@use 'sass:color';
@use '@matillion/component-library/dist/assets/styles/colours' as colours;
@use '../../../styles/designTokens' as tokens;

$spacing: 50px;

.ErrorBoundaryFallback {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  background-color: tokens.$foggy;

  &__Container {
    margin: auto;
    padding: $spacing 0;
    text-align: center;
  }

  &__Link {
    color: colours.$white;
  }

  &__Title,
  &__Description,
  &__LinkButton {
    margin: $spacing auto;
  }
}
