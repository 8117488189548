.AiNoteGenerator {
  &__ButtonGroup {
    display: flex;
    justify-content: space-between;
    padding: 0 1em 1em;
  }

  &__ErrorContainer {
    display: flex;
    flex: 1; /* take all available height*/
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
    padding: 2em;
  }

  &__ErrorIcon {
    min-width: 2em;
  }
}
