.Modal {
  padding: 30px 40px;
}

.Title {
  margin-bottom: 30px;
  text-align: center;
}

.Description {
  margin-bottom: 30px;
}

.Icon {
  display: block;
  margin: 0 auto 10px;
}

.Dismiss {
  float: right;
}
