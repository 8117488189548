@import '@matillion/component-library/dist/assets/styles/colours';

.ComponentConfigurationPanel {
  &__Steps {
    padding: 16px;
  }

  &__Controls {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
  }

  &__Accordions {
    padding: 16px;
  }

  &__Accordion {
    margin-bottom: 16px;
    border: 1px solid $cloudy;
  }

  &__Expand {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    span {
      margin-right: 8px;
    }
  }
}
