@import '@matillion/component-library/dist/assets/styles/colours';

.GridEditor {
  &__Container {
    height: 400px;
    overflow-y: scroll;
    border: 1px solid $cloudy;
  }

  &__ButtonContainer {
    display: flex;
    padding: 6px 20px;
    background-color: $misty;
  }

  &__AddRow {
    button {
      padding: 0;
    }
  }

  &__DeleteRow {
    button {
      padding: 0;
    }
  }

  &__AddAll {
    button {
      margin-right: 8px;
      padding: 8px 20px;
    }
  }
}

.FooterContainer {
  border: 1px solid $cloudy;
  border-top: 0;
}

.Alert {
  margin-bottom: 24px;
}
