@use 'styles/designTokens' as tokens;
@import '@matillion/component-library/dist/assets/styles/colours';

.ComponentOverview {
  display: flex;
  flex-direction: row;

  min-height: 60px;
  padding-left: 24px;
  border-bottom: 1px solid $cloudy;
  gap: 10px;

  &__Name {
    display: flex;
    position: relative;

    flex: 1;
    align-items: center;
    justify-content: flex-start;
    height: 32px;
    margin: auto;
  }

  &__IconBefore {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 32px;
    height: 32px;
    padding: 0;
  }

  &__Image {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__StatusContainer {
    flex: 2;
  }

  &__Status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 6px 0 6px 12px;
    border: 0;

    &--Success {
      background-color: $gale;
    }

    &--Warning {
      background-color: $sandstorm;
    }

    ul {
      margin-left: 18px;
    }

    > div > div:nth-child(2) {
      align-self: center;
    }

    button:focus::after {
      border: 0;
    }
  }
}

.ComponentPropertiesWrapper,
.ComponentProperties__TabContainer {
  position: relative;
  height: 100%;

  overflow-y: auto;
}

.ComponentPropertiesWrapper__ScrollContainer {
  overflow-y: auto;
}

.ComponentLoader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ComponentProperties__Tabs {
  height: 100%;
}

.ComponentProperties__TabPanel {
  height: calc(100% - tokens.$sample-panel-header-height);
  overflow: auto;
}

.ComponentProperties__TabList {
  position: relative;
  z-index: 2;
  border-bottom: 1px solid $cloudy;

  p {
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 18px;
  }
}

.ComponentProperties__ExternalDocsLink {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  min-width: 50px;
  padding: 8px;
}
