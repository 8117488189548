@import '@matillion/component-library/dist/assets/styles/colours';

.CreateCard {
  padding: 0;
  border: transparent;
  outline: inherit;
  background: none;
  color: inherit;
  font: inherit;
  text-align: left;
  cursor: pointer;

  &__CreateCardContainer {
    padding: 16px 16px 0 0;
  }

  &__PositiveTag {
    padding: 8px 16px;
    border-radius: 0 0 15px;
    background-color: $soft-positive;
    color: $dark-positive;
  }
}
