@use 'styles/_designTokens' as tokens;
@import '@matillion/component-library/dist/assets/styles/colours';

.PipelineTree {
  &__Row {
    display: grid;
    grid-template-columns: 1.5fr 1fr 10rem 10rem 10rem 1.5fr;
    width: 100%;
  }

  &__Cell {
    min-width: 0;
    min-height: 0;
    overflow: hidden;
  }

  &__EndOfResults {
    display: flex;
    justify-content: center;
    margin-top: 48px;
    margin-bottom: 24px;
    color: $hurricane;
  }

  &__Title {
    margin-bottom: 16px;
  }

  &__Spacer {
    height: 24px;
  }

  &__Container {
    width: 100%;
    min-width: 1200px;
    height: calc(100% - tokens.$jobs-tab-height);
    overflow: scroll;
    overflow-x: scroll;
  }
}
