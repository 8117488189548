.MovePipelineModal {
  &__Title {
    margin-bottom: 20px;
    text-align: center;
  }

  &__Icon {
    display: block;
    margin: 0 auto 10px;
  }

  &__Container {
    margin-bottom: 20px;
  }

  &__Typography {
    display: inline;
  }

  &__ConsentCheckbox {
    margin: 10px 0;
  }

  &__Buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: -8px;
    padding-top: 32px;
  }
}
