@use 'styles/_designTokens' as tokens;
@import '@matillion/component-library/dist/assets/styles/colours';

$row-width-basis: calc(100% / 10);

.Container {
  width: 100%;
  min-width: 1200px;
  height: calc(100% - tokens.$jobs-tab-height);
  overflow: scroll;
  overflow-x: scroll;
}

.ToolbarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;

  p {
    flex: 1;
    margin-left: 24px;
  }
}

.Error,
.LoadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;

  &__Icon {
    margin-bottom: 8px;
  }

  &__Text {
    max-width: 400px;
  }
}

.NoChildren {
  margin-left: 25px;
}

.DebugTreeGrid {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-height: 2em;
  margin-right: 24px;
  padding: 6px;
}

.DatagridContainer {
  height: calc(100% - tokens.$jobs-tab-height);
  overflow: auto;
}

.CodeEditor {
  flex: 1;
}
