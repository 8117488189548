@import 'components/BrowserTree/BrowserTree.module';

.Browser__Loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.BrowserTree {
  @extend .BrowserTree; // stylelint-disable-line scss/at-extend-no-missing-placeholder
}
