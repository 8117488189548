.SchemaTooltip {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &__Text {
    flex: 1;
    align-items: center;
    min-width: 0;
    overflow: hidden;
    line-height: 25px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.ContextButton {
  display: inline-block;
  margin-right: 1rem;
  padding: 0;

  .ButtonIcon {
    width: 16px;
    padding: 0;
    //@hack this overrides a default behavior from the TreeItem component
    circle {
      fill: #000 !important;
    }
  }
}

.Folder {
  display: flex;
  align-items: center;
  width: 100%;

  &__Icon {
    width: 16px;
    height: 20px;
    margin-right: 6px;
  }

  &__Text {
    flex: 1;
    min-width: 0;
    margin-top: 2px;
    line-height: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

// temporary approach until we implement drag-n-drop for schemas
.DefaultCursor {
  cursor: default;
}
