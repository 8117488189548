.AiLoadingScreen {
  &__Container {
    display: flex;
    flex: 1; /* take all available height*/
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__Message {
    max-width: 60%;
  }
}
