.ComponentSummaryList {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  &__Search {
    flex-shrink: 0;
    margin: 0 10px 10px;

    input {
      height: 34px;
    }
  }

  &__Components {
    flex-grow: 1;
    overflow-y: scroll;
  }

  &__FilterGroupWrapper {
    margin-left: 10px;
  }
}
