@use 'styles/designTokens' as tokens;
@import '@matillion/component-library/dist/assets/styles/colours';

$sample-border-bottom: 1px;

.SampleComponentForm {
  display: flex;
  align-items: center;
  border-bottom: $sample-border-bottom solid $cloudy;

  &__Container {
    display: flex;
    align-items: center;
    height: calc(tokens.$sample-panel-header-height - $sample-border-bottom);
    padding: 6px;
    border-right: 1px solid $cloudy;
    border-left: 1px solid $cloudy;

    &:first-of-type {
      border-left: 0;
    }

    &:nth-child(2) {
      margin-left: auto;
      border-right: 0;
    }
  }

  &__SampleRowCountButton {
    margin-right: 4px;
    margin-left: 8px;
    border: 1px solid $border-clr-btn-secondary-default;

    &:disabled {
      border: 0;
      background-color: $bg-clr-btn-secondary-disabled;
    }
  }
}

.SampleComponentResults {
  width: 100%;
  height: calc(100% - tokens.$sample-panel-header-height);
  overflow: auto;
}

.SampleComponentResults__Table {
  padding-right: 0;
  padding-left: 0;
  border: 0;
}

.SampleComponentResults__Row {
  display: table-row;
  // height is overridden internally so the important allows us to override it
  height: 32px !important;
}

.SampleComponentResults__Cell,
.SampleComponentResults__HeaderCell {
  display: table-cell;
  border-bottom: 1px solid $cloudy;
  text-align: left;
  word-wrap: break-word;
}

.SampleComponentResults__Cell {
  vertical-align: top;
}

.SampleComponentResults__HeaderCell {
  position: sticky;
  z-index: 1;
  top: 0;
  background-color: $misty;
}

.SampleLoading {
  padding-top: 40px;
}
