@import '@matillion/component-library/dist/assets/styles/colours';

$label-line-height: 18px;
$label-line-clamp: 3;

$unvalidated-color: $cloudy;
$success-color: $plasma-green;
$error-color: $fire-red;

@mixin generate-shadow($color) {
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: inset 0 0 0 2px $color;
  }
}

.EtlComponent {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 4px;
  border-radius: 4px;
  background-color: $white;
  box-shadow: inset 0 0 0 1px $unvalidated-color;

  &__Image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &__LabelContainer {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    flex: 1;
    align-items: baseline;
    justify-content: center;
    width: 140px;
    padding-top: 8px;
    overflow: hidden;
    transform: translateX(-50%);
    word-break: break-word;
  }

  &__Label {
    /**
     * text overflow hidden ellipsis
     * supported in evergreen browsers https://caniuse.com/?search=-webkit-line-clamp
     **/
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    max-height: calc($label-line-height * $label-line-clamp);
    overflow: hidden;
    line-height: $label-line-height;
    text-align: center;
    white-space: normal;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-line-clamp: $label-line-clamp;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-box-orient: vertical;
  }

  &__Badge {
    position: absolute;
    z-index: 1;
    right: -7px;
    bottom: -8px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.EtlComponent--isSelected {
  z-index: 2;
}

.EtlComponent--isLoading {
  @include generate-shadow($unvalidated-color);

  .EtlComponent__Badge {
    background-image: url('../../assets/validating.svg');
  }
}

.EtlComponent--isSuccess {
  @include generate-shadow($success-color);

  .EtlComponent__Badge {
    background-image: url('../../assets/success-small.svg');
  }
}

.EtlComponent--isError {
  @include generate-shadow($error-color);

  .EtlComponent__Badge {
    background-image: url('../../assets/failed-small.svg');
  }
}

.EtlComponent--isAttached {
  margin-top: -1px;
  border-radius: 0 0 4px 4px;
}

.EtlComponent--isIterator {
  // because we're using box-sizing border-box the height and width are true 40px on normal components
  // so we need to account for the border when sizing the height to be 50%
  height: 24px;

  &.EtlComponent--isAttached {
    transform: none;
    border-radius: 4px 4px 0 0;

    .EtlComponent__Badge {
      top: -8px;
      bottom: 0;
    }
  }
}

.EtlComponent--hasDoubleClickNavigation {
  cursor: pointer;
}
