@import '@matillion/component-library/dist/assets/styles/fonts';

.TextEditor {
  &__Textarea {
    @include bcs();
    flex-grow: 1;
    margin-bottom: 12px;
    padding: 12px;
    padding-top: 6px;
    overflow-y: scroll;
    border: 0;
    font-family: 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New',
      monospace;
    resize: none;

    &:hover,
    &:focus {
      // overrides default styling from MCL so this looks "invisible"
      border: 0 !important;
      box-shadow: none !important;
    }
  }
}
