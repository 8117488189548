.PipelineStatus {
  display: flex;
  align-items: center;
  justify-content: center;

  &__Icon {
    width: 18px;
    height: auto;
    margin-right: 8px;
  }
}
