.Toolbox {
  li {
    align-self: center;
  }

  &__JobIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
  }

  &__JobType {
    margin-right: 1rem;
  }
}
