.ItemContainer {
  width: 100%;

  &__Tooltip {
    margin-left: 25px;
  }
}

.Item {
  display: flex;
  align-items: center;
  width: 100%;
}

.Leaf {
  margin-left: 20px;
}

.TitleText {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Icon {
  width: 17px;
  height: 19px;
  margin-right: 6px;
}

.Text {
  flex-grow: 5;
  align-items: center;
  overflow: hidden;
  line-height: 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
}

.TreeItemWrapper {
  position: relative;
}

.DragHandle {
  position: absolute;
  top: 10px; // Centres the handle vertically in the tree item
  left: 0;
  transform: rotate(90deg);
}

.Disabled {
  opacity: 0.3;
}
