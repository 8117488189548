@import '@matillion/component-library/dist/assets/styles/colours';

.Shortcut_Keys {
  padding: 0;
  color: $hurricane;
  text-transform: uppercase;
  user-select: none;
}

.MacOs {
  font-family: Telegraf, -apple-system, Arial, Helvetica, sans-serif;
}
