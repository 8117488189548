@use 'styles/_designTokens' as tokens;
@import '@matillion/component-library/dist/assets/styles/colours';

$row-width-basis: calc(100% / 10);

.Container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.ToolbarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;

  p {
    flex: 1;
    margin-left: 24px;
  }
}

.LoadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.Cell {
  border-left: 1px solid $cloudy;

  &__Expander {
    display: flex;
    flex: 1 0;
    align-items: center;
    min-width: 500px;

    div:first-child {
      min-width: 26px;
      margin-right: 2px;
    }

    *:last-child {
      white-space: nowrap;
    }
  }

  &__Component {
    flex: 1 0;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__Message {
    flex: 0 0 250px;
  }

  &__TimeDuration {
    flex: 0 0 125px;
  }

  &__RowCount {
    flex: 0 0 150px;
  }
}

.TaskStatus {
  display: flex;
  align-items: center;

  &__Icon {
    width: 18px;
    height: auto;
    margin-right: 8px;
  }
}

.RowExpandTrigger {
  margin: 0 0 0 -2px;

  svg {
    width: 14px;
    height: 14px;
    margin-top: 1px;
    margin-left: 2px;
    transform: rotate(-90deg);
  }

  &:focus {
    outline: none;

    &::after {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 2px solid $black;
    }
  }
}

.CellMessage {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__Text {
    max-width: 180px;
  }

  &__CTA {
    flex: 0 0;
  }
}

.CellMessageModal {
  &__Title {
    margin-bottom: 32px;
  }

  &__Controls {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
  }
}

.expanded {
  svg {
    margin-top: 2px;
    margin-left: 0;
    transform: rotate(0deg);
  }
}

.NoChildren {
  margin-left: 25px;
}

.DebugTreeGrid {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-height: 2em;
  margin-right: 24px;
  padding: 6px;
}

// TODO[DPCD-492] This is a bit of a hack bypassing the lack of colspan in DataGrid
:global(.DataGrid-module__DataGrid--etlDesigner__Row__Content:hover) {
  .FakeColSpan {
    background-color: $misty;
  }
}

.FakeColSpan {
  z-index: 1;
  padding: 5px;
  background-color: $white;
}

.DatagridContainer {
  height: calc(100% - tokens.$jobs-tab-height);
  overflow: auto;
}
