.ZoomToolbar {
  position: relative;
  margin-top: 0;
  padding-top: 0;
}

.ZoomToolbar__Percentage {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 5em;
  height: 30px;
}

.ZoomToolbar__Button {
  height: 30px;
  margin: 0;
  padding: 0;
}
