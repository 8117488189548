@import '@matillion/component-library/dist/assets/styles/colours';

$indicator-thickness: 1px;
$bar-thickness: 16px;
$zindex: 10;

%base-resize-container {
  background-color: $cloudy;
}

.ResizeableHandler {
  z-index: $zindex;
  background-color: transparent;

  &--Inactive {
    @extend %base-resize-container;
  }

  &--Horizontal {
    height: $bar-thickness + $indicator-thickness;
    margin: (-(calc($bar-thickness/2))) 0;
  }

  &--Vertical {
    width: $bar-thickness + $indicator-thickness;
    margin: 0 (-(calc($bar-thickness/2)));
  }

  &--VerticalWithDragHandle {
    width: $bar-thickness;
  }

  &--Active {
    &:hover {
      .ResizeableHandler__Indicator {
        background-color: $electric-green;
      }
    }

    &:active {
      .ResizeableHandler__Indicator {
        background-color: $plasma-green;
      }
    }
  }

  &__Indicator {
    background-color: $cloudy;

    &--Horizontal {
      height: $indicator-thickness;
      margin: calc($bar-thickness/2) 0;
    }

    &--Vertical {
      width: $indicator-thickness;
      height: 100%;
      margin: 0 calc($bar-thickness/2);
    }
  }

  &__DragHandle {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    margin-left: 10px;

    svg {
      width: 15px;
      height: 5px;
      transform: rotate(90deg);
    }
  }
}
