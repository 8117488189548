.CenteredSpinner {
  display: flex;
  align-items: self-start;
  justify-content: center;
  width: 100%;

  &--normal {
    padding: 16px;
  }

  &--compact {
    padding: 8px;

    svg {
      width: 60px;
    }
  }
}
