@import '@matillion/component-library/dist/assets/styles/colours';

.EditPanel {
  height: 100%;
  padding: 20px 36px;
  overflow: hidden;

  &__Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__Title {
    margin-bottom: 8px;
  }

  &__SubText {
    color: $hurricane;
  }
}
