@import '@matillion/component-library/dist/assets/styles/colours';

$opaque-grey: rgba($black, 0.1);

.AddNextComponentCTA {
  z-index: 5;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 9px;

  border: 0;
  background-color: $opaque-grey;
  color: inherit;
  font: inherit;
  text-align: left;
  cursor: pointer;
  appearance: none;

  &__OutputPortSelectorWrapper {
    margin: 0 -40px 10px;
    padding: 15px 50px;
    background-color: $sandstorm;
  }

  &__OutputPortSelector {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    list-style: none;
  }
}

.ModalContainer {
  display: flex;
  flex-direction: column;
  height: 70vh;

  &__Title {
    flex-shrink: 0;
    margin: 0 10px 10px;
  }

  &__Content {
    flex-grow: 1;
    overflow: hidden;
  }
}

.Loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
