@import '@matillion/component-library/dist/assets/styles/colours';
@import '@matillion/component-library/dist/assets/styles/fonts';
@import './EtlNoteThemes.module';

.EtlNote {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 4px;
  outline: 1px solid $cloudy;
  background-color: $white;

  &__DragBar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &__Toolbar {
    display: flex;
    border: 1px solid $cloudy;
    border-radius: 6px;
    background-color: $white;
  }

  &--isSelected {
    z-index: 2;
    border-radius: 4px;
    box-shadow: 0 0 0 2px $cloudy, 0 0 0 8px rgba($black, 0.1);
  }

  &__Themes {
    @each $name, $colour in $note-colour {
      &--#{$name} {
        > .EtlNote__DragBar {
          background: $colour;

          circle {
            fill: map-get($map: $dots-colour, $key: $name);
          }
        }

        &.EtlNote {
          background: map-get($map: $note-background, $key: $name);
        }
      }
    }
  }
}
