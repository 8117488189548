.MarkdownTextViewer {
  &__Markdown {
    flex-grow: 1;
    margin-bottom: 12px;
    padding: 12px;
    padding-top: 6px;
    overflow-y: scroll;
    cursor: text;
  }
}
