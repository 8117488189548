@import '@matillion/component-library/dist/assets/styles/colours';

.ButtonWithText {
  display: flex;
  align-items: center;
  margin: 0;
  padding-left: 10px;
  border: 0;
  background: $white;
  cursor: pointer;

  &:hover {
    background-color: $misty;
  }
}

.ButtonIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
}

.Tooltip {
  padding: 0.3rem 0.75rem;
}
