@import '@matillion/component-library/dist/assets/styles/colours';

.PopOver {
  z-index: 100;
  width: 360px;

  &__Content {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    list-style: none;
  }

  &__Section {
    // -8px on top and bottom to account for padding on items
    padding: 16px 0;
    list-style-type: none;
    border-bottom: 1px solid $cloudy;
  }
}

.PopOverItem {
  width: 100%;
  padding: 4px 24px;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &__Text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    white-space: nowrap;

    > span {
      max-width: 75%;
    }
  }

  &:hover {
    background-color: #f2f2f2;
  }

  &--Disabled {
    background: $white;
    color: $windy;

    &:hover {
      background-color: $white;
      cursor: default;
    }
  }

  &__Shortcut {
    color: $hurricane;
    text-transform: uppercase;
    user-select: none;
  }
}
