@import '@matillion/component-library/dist/assets/styles/colours';
@import '@matillion/component-library/dist/assets/styles/fonts';

.ParameterOverlay {
  min-width: 400px;
  padding: 24px;
  overflow-y: hidden;

  &__Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 24px;
    padding-bottom: 32px;

    &_Actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__FooterContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 48px;
    gap: 16px;
  }

  &__GridVariable {
    display: flex;
    padding-right: 32px;

    &_Field label {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      span {
        @include brand-font();
        margin-bottom: 0;
        margin-left: 8px;
        font-weight: 400;
      }
    }
  }

  &__DocButton {
    padding-right: 8px;
  }
}

.Error:last-of-type {
  margin-bottom: 32px;
}
