@import '@matillion/component-library/dist/assets/styles/colours';

.NestedPipelineSteps {
  &__FullWidthRow {
    border-top: 1px solid $cloudy;
    background-color: $white;
  }

  &__ErrorMessage {
    margin: 20px;
  }
}
