@import '@matillion/component-library/dist/assets/styles/colours';

.ErrorMessage {
  display: flex;
  justify-content: center;
  margin-top: 48px;
  color: $hurricane;
  text-align: center;

  &__Icon {
    margin-right: 8px;
    margin-bottom: 8px;
  }
}
