@import '@matillion/component-library/dist/assets/styles/colours';

.Name {
  overflow: hidden;
  color: $plasma-green;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Cell {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  border: 0;
  background-color: transparent;
}

.Icon {
  margin-right: 8px;
}
