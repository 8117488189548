@import '@matillion/component-library/dist/assets/styles/colours';

.Tooltip {
  &__Container {
    padding: 0.3rem 0.75rem;
  }

  &__IconButton {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 44px;
    border: 0;
    outline: none;
    background: none;
    cursor: pointer;

    &__FixedSize {
      width: 44px;
    }

    &:focus {
      border: 1px solid $black;
    }
  }

  &__IconButton[disabled] {
    cursor: default;
  }
}
