@import '@matillion/component-library/dist/assets/styles/colours';

// ensure padding aligns with inline freetext and dropdown editors
$input-padding-left: 2px;
$input-margin-left: 2px;

.ModalTriggerEditor__Button {
  display: flex;
  align-items: center;
  height: 32px;
  margin-right: 2px;
  margin-left: $input-padding-left;
  padding-left: $input-padding-left;
  border: 0;
  border-radius: 0;
  background: none;
  text-align: left;
  cursor: pointer;

  span {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    padding-left: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-line-clamp: 1;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-box-orient: vertical;
  }

  &--Error {
    margin-right: 1px;
    border: 1px solid transparent;
    box-shadow: 0 0 0 1px $fire-red;

    &:hover,
    &:focus {
      border-right: 1px solid $black;
      box-shadow: 0 0 0 2px $black;
    }
  }

  &:hover,
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $black;
  }
}

.ModalTriggerEditor__Icon {
  width: 18px;
  height: 18px;
  margin-right: 12px;
  margin-left: auto;
}

.ModalTriggerEditor__Value {
  flex: 1;
}

.EditorNotSupported {
  padding-top: 8px;
}
