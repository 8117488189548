.CellMessage {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__Text {
    max-width: 180px;
  }

  &__CTA {
    flex: 0 0;
  }
}

.CellMessageModal {
  &__Title {
    margin-bottom: 32px;
  }

  &__Controls {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
  }

  &__Loading {
    margin-top: 16px;
  }
}

.CodeEditorContainer {
  height: 300px;
}
