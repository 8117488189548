@use 'styles/designTokens' as tokens;
@import '@matillion/component-library/dist/assets/styles/colours';

.RightPanel {
  height: calc(100vh - tokens.$proj-toolbar-height - tokens.$nav-header-height);
  border-left: 1px solid $cloudy;

  &__Content {
    height: 100%;
  }
}

.ApplicationContent {
  width: 100vw;
  height: 100%;
}

.CanvasContainer {
  height: 100%;
  overflow: auto;
}
