@use '@matillion/component-library/dist/assets/styles/colours' as colours;

.FilterGroup {
  display: flex;
  flex-flow: row wrap;
  margin: 0 0 1rem;

  &__input {
    position: absolute;
    clip: rect(0 0 0 0);
    pointer-events: none;
  }

  &__pill {
    display: inline-block;
    margin: 0 0.5rem 0.5rem 0;
    padding: 2px 8px;
    border: 1px solid colours.$cloudy;
    border-radius: 100px;
    background-color: colours.$white;
    color: colours.$hurricane;
    cursor: pointer;

    &--checked {
      border: 1px solid colours.$gale;
      background-color: colours.$gale;
      color: colours.$black;
    }
  }

  &__label {
    &:focus-within {
      > p {
        outline: 1px solid colours.$black;
      }
    }
  }
}
