@import '@matillion/component-library/dist/assets/styles/colours';

.MainTextContainer {
  display: flex;
  align-items: center;

  &--noWrap {
    flex-wrap: nowrap;
    white-space: nowrap;
  }

  &__Text {
    &--noWrap {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.Subtext {
  color: $hurricane;
  // 14px is more legible than the designs
  font-size: 12px;

  &--noWrap {
    // restrict the subtext to one line, so that if we have both text and subtext then it takes up max 2lines
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.SubtextContainer {
  display: flex;
  align-items: center;
  color: $hurricane;

  &__IconContainer {
    display: flex;
    align-items: center;
    margin-right: 4px;
  }
}
