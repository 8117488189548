@import '@matillion/component-library/dist/assets/styles/colours';

.ComponentSummaryListItem {
  display: grid;
  grid-gap: 0;
  grid-template-columns: auto auto 1fr auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 28px;
  padding: 3px 9px 3px 4px;
  border: 1px solid transparent;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    border: 1px solid $black;
    outline: none;
  }

  &:hover {
    background-color: $misty;
    user-select: none;

    .DragHandle:not(.DragHandle__Invisible) {
      visibility: visible;
    }
  }

  &__Icon {
    height: 20px;
    margin-right: 10px;

    img {
      width: 27px;
      height: 100%;
      object-fit: contain;
    }
  }

  &__Badge {
    text-align: right;
    white-space: nowrap;
  }

  &__Tag {
    color: #6c6c6c;
  }

  &__Draggable {
    cursor: grab;
  }

  &__Unselectable {
    cursor: default;
  }
}

.DragHandle {
  visibility: hidden;
  transform: rotate(90deg);
}
