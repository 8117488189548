@use 'modules/observability/PipelineRunExplorer/components/PipelineTable/constants'
  as tableConstants;

.PipelineTreeHead {
  &__PipelineColumn {
    // This is calculated from the normal padding + internal cell panel + dropdown icon width
    // This means the Pipeline column starts its text at the same indent as the rest of the content
    padding-left: calc(tableConstants.$cell-padding-left + 10px + 28px);
  }
}
