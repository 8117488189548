@import '@matillion/component-library/dist/assets/styles/colours';

.PipelineNameCell {
  display: flex;
  padding: 10px;

  &__Expander {
    display: flex;
    flex: 1 0;
    align-items: center;

    div:first-child {
      min-width: 26px;
      margin-right: 2px;
    }
  }
}

.RowExpandTrigger {
  margin: 0;

  svg {
    width: 14px;
    height: 14px;
    margin-top: 1px;
    margin-left: 2px;
    transform: rotate(-90deg);
  }

  &:focus {
    outline: none;

    &::after {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 2px solid $black;
    }
  }
}

.expanded {
  svg {
    margin-top: 2px;
    margin-left: 0;
    transform: rotate(0deg);
  }
}
