@use './constants' as constants;

.PipelineTable {
  position: relative;
  width: 100%;
  // This is used in conjunction with 100% height on table rows to make the table fill the height of its container
  height: fit-content;
  border-spacing: 0;
  border-collapse: separate;
  text-align: left;
}

.PipelineTableCell {
  padding: constants.$cell-padding;
  border-right: 1px solid constants.$border-colour;

  &--no-wrap {
    white-space: nowrap;
  }

  &--full-width {
    width: 100%;
    padding: 0;
  }
}

.PipelineTableHeader {
  position: relative;
  z-index: 1;
  border-bottom: 1px solid constants.$border-colour;
  background-color: constants.$header-background-colour;

  &--sticky {
    position: sticky;
    top: 0;
  }
}

.PipelineTableRow {
  // This allows td/th to know the available height and makes children of td/th fill the height of the row
  height: 100%;

  // rows adjacent to each other should have a border between them
  & + & {
    border-top: 1px solid constants.$border-colour;
  }
}

.PipelineTableRow:last-child {
  border-bottom: 1px solid constants.$border-colour;
}
